import {Get, Post} from "./http";
 let baseUrl = "http://localhost:8082";


//test用正式接口。有跨域问题
//https://pintuan-h5.catdogtest.com/at20250401?islogin=1&istest=1&memberId=056a69e6-83ee-401d-8f5f-5b495d12738c&sign=ed65239c4adaa964e29055e7bd8115d6&timestamp=1728632611
//http://localhost:1234/at20250401?islogin=1&istest=1&memberId=056a69e6-83ee-401d-8f5f-5b495d12738c&sign=ed65239c4adaa964e29055e7bd8115d6&timestamp=1728632611
//export let domainUrl = "https://pintuan-h5.catdogtest.com"

export let domainUrl =  location.origin
baseUrl=domainUrl;
export const login = async function ({memberId, timestamp, sign, param}){
    return await Get(
      `${baseUrl}/s2301/login?memberId=${memberId}&timestamp=${timestamp}&sign=${sign}&param=${param}`
    )
};

export const getActivityList = async function (token){  return await Get(

    `${baseUrl}/s2301/group/activity`, token
  )
}

export const getActivityInfo= async function (id, dumplingCode, token, debug){
  let url = `${baseUrl}/s2301/group/activityInfo?activitysId=${id}${dumplingCode? '&dumplingCode=' + dumplingCode : ''}`
  if (debug)
    alert(url)
  return await Get(
    url
    ,
    token
  )
}

export const getActivityCreate= async function (id, dumplingCode, token){
  return await Get(
    `${baseUrl}/s2301/order/activityCreate?activitysId=${id}${dumplingCode? '&dumplingCode=' + dumplingCode : ''}`,
    token
  )
}

export const getOtherActivity = async function (token){
  return await Get(
    `${baseUrl}/s2301/group/otherActivity`, token
  )
}



export const getProjectList = async function (token){
  return await Get(
    `${baseUrl}/s2301/group/project`, token
  )
}

export const getProjectInfo = async function (projectId,discountId, dumplingCode,token){
  return await Get(
    `${baseUrl}/s2301/group/projectInfo?projectId=${projectId}&discountId=${discountId}${dumplingCode? '&dumplingCode=' + dumplingCode : ''}`, token
  )
}


export const getProjectCreate= async function (id, discountId , dumplingCode, token) {
  return await Get(
    `${baseUrl}/s2301/order/projectCreate?projectId=${id}&discountId=${discountId}${dumplingCode ? '&dumplingCode=' + dumplingCode : ''}`,
    token
  )
}

export const getRules = async function (type,token){
  return await Get(
    `${baseUrl}/s2301/group/rules?type=${type}`, token
  )
}



export const getProjectRule = async function(id, type, token){
  return await Get(
    `${baseUrl}/s2301/group/projectRule?type=${type}&projectId=${id}`, token
  )
}


///s2301/order/list
export const getOrderList = async function(paymentStu, token){
  return await Get(
    `${baseUrl}/s2301/order/list?paymentStu=${paymentStu}`, token
  )
}


export const addShare = async function (activityId, sharerMemberId, receiveMemberId,  type, token) {
  return await Post(`${baseUrl}/s2301/sharing/add`, {activityId, sharerMemberId, receiveMemberId, type}, token)
}
