import { Link, useLocation, useParams } from "react-router-dom";
import {useCallback, useContext, useEffect, useRef, useState} from "react";
import { Helmet } from "react-helmet";
import Avatar from "../../components/avatar";
import {checkOrderPay, setOrderPay, substr} from "../../utils";
import TokenContext from "../../TokenContext";
import XmptDetail from "../../components/xmpt-detail";
import XmptCountdown from "../../components/xmpt-countdown";
import XmptParticipant from "../../components/xmpt-participant";
import {getProjectInfo, getProjectRule, getRules} from "../../api";
import CustomAlert from "../../components/custom-alert";

export default function Xmpt() {
  const { itemId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dumplingCode = searchParams.get("dumplingCode");
  const discount = searchParams.get("discount");
  const payMsg = searchParams.get("pay_msg");
  const { token } = useContext(TokenContext);
  const [project, setProject] = useState(null);
  const [detail, setDetail] = useState("");
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(5);
  const [isCheck, setIsCheck] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const type = searchParams.get("type");
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState(false);


  const handleShowAlert = (message) => {
    setShowAlert(true);
    setMessage(message)
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
    setMessage(null);
    loadProjectData();
  };

  useEffect(() => {
    if (!isCheck && paymentSuccess) {
      setIsCheck(true);
    }
  }, [isCheck, paymentSuccess]);

  let intervalRef = useRef(null);

  const loadProjectData = async (checkPay) => {
    try {
      // setLoading(true)
      const projectResp = await getProjectInfo(itemId, discount, dumplingCode, token);
      setProject(projectResp.result);
      const rulesResp = await getRules(2, token);
      setDetail(rulesResp.result?.orderActivityDo);
    } catch (error) {
      console.error("Error occurred while loading project data:", error);
    } finally {
      // setLoading(false);
    }
  };

  useEffect(function () {
    let flag = checkOrderPay(project?.orderProject?.orderCode)
    if(!flag){
      if (project && project.paymentStu === 1 && isCheck) {
        handleShowAlert("支付成功");
        setPaymentSuccess(true)
        setIsCheck(false)
        setCount(0)
        setOrderPay(project.orderProject.orderCode, project.orderProject)
      } else {
        if (count === 1 && isCheck){
          handleShowAlert(`支付失败(${project.paymentStu || '0'})`);
          setPaymentSuccess(false)
          setIsCheck(false)
        }
      }
    }else {
      setCount(0)
    }
  },[count])

  const handlePaymentSuccess = useCallback(() => {
    setPaymentSuccess(true);
  }, []);


  useEffect(() => {
    if (!checkOrderPay(project?.orderProject?.orderCode) && payMsg === "pay_success") {
      // setLoading(true)
      intervalRef.current = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount === 0) {
            clearInterval(intervalRef.current);
            // setLoading(false)
            return prevCount
          }
          loadProjectData();
          setIsCheck(true)
          return prevCount - 1;
        });
      }, 1000);
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    loadProjectData();
  }, [itemId, discount, dumplingCode]);

  if (!project || loading) {
    return (
      <>
        <div className="loading">
          <div className="loading-bar"></div>
          <div className="loading-bar"></div>
          <div className="loading-bar"></div>
          <div className="loading-bar"></div>
          <div className="loading-bar"></div>
        </div>
        <p style={{ textAlign: "center", color: "rgb(255 255 255)" }}>
          {project ? `检查支付(${count})` : "加载中"}
        </p>
      </>
    );
  }
  return (
    <main>
      <Helmet>
        <title>{project.projectName || ""}</title>
      </Helmet>
      <section>
        <XmptDetail project={project} />
        <div className="czpt-content">
          <XmptCountdown project={project} load={loadProjectData} />
          <XmptParticipant czpt={project} load={loadProjectData}/>
        </div>

        {project.otherDumpling && type !== 'share'? (
          <div className="czpt-content">
            <h4 className={"czpt-content-title"}>也可以参加以下拼团</h4>
            <div className={"qt-pt"}>
              {project.otherDumpling.map((pt) => (
                <div className={"qt-pt-item"} key={pt.projectId}>
                  <Avatar src={pt.projectImage} />
                  <div className={"qt-pt-item-context"}>
                    {substr(pt.projectName, 10)}
                    <span className={"tips"}>
                      还差<span>{pt.differNum}人</span>拼成
                    </span>
                  </div>
                  {/*<button>*/}
                    <Link className={"btn"}
                      to={`/xmpt20241202/${pt.projectId}?dumplingCode=${pt.dumplingCode}&discount=${pt.discountId}`}
                    >
                      参与拼团
                    </Link>
                  {/*</button>*/}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="czpt-activity-detail">
          <h4 className="czpt-activity-detail-title">项目拼团规则</h4>
          <div className="czpt-activity-detail-context">
            <div
              className={"htmlContent"}
              dangerouslySetInnerHTML={{ __html: detail }}
            ></div>
          </div>
        </div>
      </section>
      {showAlert && (
        <CustomAlert
          message={message}
          onClose={handleCloseAlert}
        />
      )}
    </main>
  );
}
