import xmpt_img1 from "../../../img/xmpt-item2@2x.png";
import Avatar from "../avatar";
import {substr} from "../../utils";

import avatar_img from '../../../img/132.jpeg'
import horizontalICON from '../../../img/horizontal.svg'
import otherICON from '../../../img/other.svg'
import {Link} from "react-router-dom";
import WdptCountdown from "../wdpt-countdown";
import {domainUrl, getActivityCreate} from "../../api";
import sharIcon from "../../../img/分享图标.png";
import pageImg from "../../../img/分享背景图B@2x20250401.png"; 
import moment from "moment";

export default function WdptItemCard({order, load}) {

  const toMyAccount = () => {
    let path  = "/pages/my/account";
    // alert("跳转：" + path)
    wx.miniProgram.navigateTo({url:path})
  }

  const toMyProject = () => {
    let path  = "/pages/my/testitemcoupon";
    // alert("跳转：" + path)
    wx.miniProgram.navigateTo({url:path})
  }

  const toPay = async (order) => {
    if (!order) {
      return alert("订单不存在")
    }


    let path = "/pages/index/webPay";
    path += "?orderSn=" + order.orderCode;
    path += "&price=" + order.orderActivityDo * 100;
    path += "&goodsName=" + order.projectName;
    path += order.type === 0?"&type=32" : "&type=33"

    let backUrl = domainUrl;
    backUrl += order.type === 0 ? '/czpt20250401/' + order.projectId : '/xmpt20250401/' + order.projectId;
    backUrl += order.type === 0 ? "?dumplingCode=" + order.dumplingCode : "?dumplingCode=" + order.dumplingCode + '&discount=' + order.discountId;
    backUrl += "&type=payMsg"
    backUrl += '&islogin=1'
    backUrl += "&isnosharebtn=true"

    let successH5Url = backUrl + "&pay_msg=" + 'pay_success';
    let errorH5Url = backUrl + '&pay_msg=' + 'pay_error'


    console.log("successH5Url:"+successH5Url)
    console.log("errorH5Url:"+backUrl)

    path += "&successH5UrlBase64UriCode=" + encodeURIComponent(btoa(successH5Url));
    path += "&errorH5UrlBase64UriCode=" + encodeURIComponent(btoa(errorH5Url));
    console.log('订单支付小程序path:'+path)
    wx.miniProgram.navigateTo({url: path})

  }

  const toShare = (order) => {
    let url = "/pages/index/webShare";

    if (!order.dumplingCode) {
      alert("请先参团。")
    }

    let MEMBER_ID = localStorage.getItem('MEMBER_ID');

    let h5Url = domainUrl

    h5Url += order.type === 0 ? '/czpt20250401/' + order.projectId : '/xmpt20250401/' + order.projectId;
    h5Url += order.type === 0 ? "?dumplingCode=" + order.dumplingCode : "?dumplingCode=" + order.dumplingCode + '&discount=' + order.discountId;
    h5Url += "&type=share";
    h5Url += '&shareMemberId=' + MEMBER_ID;
    h5Url += '&islogin=1'
    h5Url += "&isnosharebtn=true"


    console.log("分享链接：" + h5Url)
    //
    h5Url = btoa(h5Url);
    h5Url = encodeURIComponent(h5Url);

    // //分享链接为path
    let path = "/pages/index/webView?webViewSrcBase64UriCode=" + h5Url;
    path = btoa(path);
    path = encodeURIComponent(path);

    url += "?pathBase64UriCode=" + path;
    url += "&title=" + order.projectName;
    url += "&pageTitle=" + order.projectName;
    url += "&btnStyle=1";
    // url += "&imageUrl=" + sharImg;
    url += "&imageUrl=" + pageImg;
    url += "&pageImageUrl=" + pageImg;
    console.log("分享：" + path)
    console.log(url)

    wx.miniProgram.navigateTo({url: url});
  }


  const getDumplingResponse = (dumplingResponse, size) => {

    if (dumplingResponse.length > 5) {
      return <>
        {Array.apply(null, Array(4)).map(function (_, index) {
          return <Avatar key={index} src={dumplingResponse[index]?.avatar || avatar_img}/>
        })}
        <Avatar bgColor={'rgb(250,250, 250)'} src={horizontalICON}/>
      </>
    }

    if (size > 5) {
      return <>
        {Array.apply(null, Array(4)).map(function (_, index) {
          if (dumplingResponse[index]) {
            return <Avatar key={index}
                           src={dumplingResponse[index].avatar || avatar_img}/>
          }
          return <Avatar/>
        })}
        <Avatar bgColor={'rgb(250,250, 250)'} src={horizontalICON}/>
      </>
    }

    return <>
      {Array.apply(null, Array(size)).map(function (_, index) {
        if (dumplingResponse[index]) {
          return <Avatar key={index}
                         src={dumplingResponse[index].avatar || avatar_img}/>
        }
        return <Avatar/>
      })}
    </>
  }

  return <div className={"wdpt-item-card"}>
    {
      order.state === 1 ?
        <div className={"wdpt-item-status1"}>
        </div> : <></>
    }
    {
      order.state === 2 ?
        <div className={"wdpt-item-status2"}>
        </div> : <></>
    }
    {
      order.state === 3 ?
        <div className={"wdpt-item-status3"}>
        </div> : <></>
    }
    {
      order.state === 4 ?
        <div className={"wdpt-item-status4"}>
        </div> : <></>
    }
    <div className={"wdpt-item-card-header"}>
      {
        (order.paymentStu === 1 || order.paymentStu === 2) && order.paymentTime ? <p className={"tips"}>
          支付时间：{
          moment(order.paymentTime).format('YYYY.MM.DD HH:mm:ss')
        }
        </p> : <></>
      }

    </div>
    <div className={"wdpt-item-card-body"}>
      <div className={"wdpt-item-card-content"}>
        <div className="wdpt-item-card-img-container">
          <img className="wdpt-item-card-img" alt={order.projectImage} src={order.projectImage}/>
        </div>
        <div className="wdpt-item-card-context">
          <h4 className="wdpt-item-card-context-title">{substr(order.projectName, 20)}</h4>
          <p className="wdpt-item-card-context-sn">
            订单编号：{order.orderCode}
          </p>
          <div style={{
            display: 'flex',
            flexGrow: 1,
            alignItems: 'flex-end'
          }}>
            <div className={"avatars-container"}>
              {
                order.paymentStu === 3?
               <></>
                :  getDumplingResponse(order.dumplingResponse, order.peopleNumber)
              }
            </div>
            <div>
              {
                order.type == 1 ? <p className="wdpt-item-card-context-original-price">
                  原价<span>￥{order.projectOriginalPrice}</span>
                </p> : <></>
              }
              <p className="wdpt-item-card-context-activity-price">
                活动价<span>￥{order.orderActivityDo}</span>
              </p>
              <p className="wdpt-item-card-context-pay-price">
                实付<span>￥{
                order.paymentStu === 4 || order.paymentStu === 2 || order.paymentStu ===1
                 ? order.orderActivityDo : '0'
                }</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      {
        order.state === 3 && (order.paymentStu === 1 || order.paymentStu === 2 )?
          <div className={"tksj"}>
            系统自动退款，<span>1-3个</span>工作日到账
          </div>
          :
          <></>
      }
    </div>
    <div className={"wdpt-item-card-footer"}>

      {
        order.state === 1 ?
          <>
            <div style={{flexGrow: 1}}>
              <p className={"tips"}>成团时间：
                {
                    moment(order.clusteringTime).format('YYYY.MM.DD HH:mm:ss')
                }
              </p>
            </div>
            {
              order.type == 1 ?
                <button className={"outlined"} style={{
                  marginRight: '10px'
                }}
                        onClick={toMyProject}
                >
                  我的项目
                </button>
                :
                <button className={"outlined"} style={{
                  marginRight: '10px'
                }}
                        onClick={toMyAccount}
                >
                  我的余额
                </button>
            }
            <button>
             <Link to={"/at20250401?a=1"}>
               更多拼团
             </Link>
            </button>
          </>
          : <></>
      }
      {
        order.state === 2 ?
          <>
            <div style={{flexGrow: 1}}>
              <WdptCountdown czpt={order}/>
            </div>
            <button className={"outlined"} style={{
              marginRight: '10px'
            }}
                    onClick={() => toShare(order)}
            >分享
            </button>
            {/*<button>*/}
            {/*  <Link to={"/at20250401?a=1"}>*/}
            {/*    更多拼团*/}
            {/*  </Link>*/}
            {/*</button>*/}
          </>
          : <></>
      }
      {
        order.state === 3 ?
          <>
            <div style={{flexGrow: 1}}>
              <p className={"tips"}>
                {

                  order.paymentStu === 2? '退款时间：' + moment(order.refundTime).format('YYYY.MM.DD HH:mm:ss'):
                  order.paymentStu === 3? '支付超时': ''
                }
              </p>
            </div>
            <button>
             <Link to={"/at20250401?a=1"}>
               更多拼团
             </Link>
            </button>
          </>
          : <></>
      }
      {
        order.state === 4 ?
          <>
            <div style={{flexGrow: 1}}>
              <WdptCountdown czpt={{endTime: order.payEndTime}} load={load} title={"支付倒计时"}/>
            </div>
            {/*<button className={"outlined"} style={{*/}
            {/*  marginRight: '10px'*/}
            {/*}}*/}
            {/*        onClick={() => toShare(order)}*/}
            {/*>分享*/}
            {/*</button>*/}
            <button
              onClick={() => toPay(order)}
            >去支付
            </button>
          </>
          : <></>
      }
    </div>
  </div>
}
