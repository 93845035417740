import {Link, useLocation, useParams} from "react-router-dom";
import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {Helmet} from "react-helmet";
import Avatar from "../../components/avatar";
import {checkOrderPay, setOrderPay, substr} from "../../utils";
import TokenContext from "../../TokenContext";
import CzptDetail from "../../components/czpt-detail";
import CzpyCountdown from "../../components/czpy-countdown";
import CzptParticipant from "../../components/czpt-participant";
import {getActivityInfo, getProjectRule, getRules} from "../../api";
import CustomAlert from "../../components/custom-alert";

export default function Czpt() {
  const {itemId} = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dumplingCode = searchParams.get("dumplingCode");
  const payMsg = searchParams.get("pay_msg");
  const [czpt, setCzpt] = useState(null);
  const {token, updateToken} = useContext(TokenContext);
  const [detail, setDetail] = useState("");
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(5);
  const [isCheck, setIsCheck] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const type = searchParams.get("type");
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState(false);

  const handleShowAlert = (message) => {
    setShowAlert(true);
    setMessage(message)
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
    setMessage(null);
    loadCzptData();
  };

  let intervalRef = useRef(null);

  const loadCzptData = async () => {
    try {
      const czptResp = await getActivityInfo(itemId, dumplingCode, token);
      setCzpt(czptResp.result);
      const rulesResp = await getRules(1, token);
      setDetail(rulesResp.result?.orderActivityDo);
    } catch (error) {
      console.error("Error occurred while loading CZPT data:", error);
    } finally {
      // setLoading(false);
    }
  };

  useEffect(function () {
    let flag = checkOrderPay(czpt?.orderActivity?.orderCode)
    if(!flag) {
      if (czpt && czpt.paymentStu === 1 && isCheck) {
        handleShowAlert("支付成功");
        setPaymentSuccess(true)
        setCount(0)
        setIsCheck(false)
        setOrderPay(czpt.orderActivity.orderCode, czpt.orderActivity)
      } else {
        if (count === 1 && isCheck) {
          handleShowAlert(`支付失败（${czpt.paymentStu || '0'})`);
          setPaymentSuccess(false)
          setIsCheck(false)
        }
      }
    } else {
        setCount(0)
      }
  },[count])

  const handlePaymentSuccess = useCallback(() => {
    setPaymentSuccess(true);
  }, []);

  useEffect(() => {
    loadCzptData();
  }, [itemId, dumplingCode]);


  useEffect(() => {
    if (!checkOrderPay(czpt?.orderActivity?.orderCode) && payMsg === "pay_success") {
      // setLoading(true)
      intervalRef.current = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount === 0) {
            clearInterval(intervalRef.current);
            // setLoading(false)
            return prevCount
          }
          loadCzptData();
          setIsCheck(true)
          return prevCount - 1;
        });
      }, 1000);
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  if (!czpt || loading) {
    return (
      <>
        <div className="loading">
          <div className="loading-bar"></div>
          <div className="loading-bar"></div>
          <div className="loading-bar"></div>
          <div className="loading-bar"></div>
          <div className="loading-bar"></div>
        </div>
        <p style={{textAlign: "center", color: "rgb(255 255 255)"}}>
          {czpt ? `检查支付(${count})` : "加载中"}
        </p>
      </>
    );
  }
  return (
    <main>
      <Helmet>
        <title>{czpt.activityName || ""}</title>
      </Helmet>
      <section>
        <CzptDetail czpt={czpt}/>
        <div className="czpt-content">
          <CzpyCountdown czpt={czpt} load={loadCzptData}/>
          <CzptParticipant czpt={czpt} load={loadCzptData}/>
        </div>
        {czpt.otherDumpling && type !== 'share' ? (
          <div className="czpt-content">
            <h4 className={"czpt-content-title"}>也可以参加以下拼团</h4>
            <div className={"qt-pt"}>
              {czpt.otherDumpling.map((pt) => (
                <div className={"qt-pt-item"} key={pt.activityId}>
                  <Avatar src={pt.activityImage}/>
                  <div className={"qt-pt-item-context"}>
                    {substr(pt.activityName, 10)}
                    <span className={"tips"}>
                      还差<span>{pt.differNum}人</span>拼成
                    </span>
                  </div>
                  {/*<button>*/}
                  <Link className={"btn"}
                        to={`/czpt20250401/${pt.activityId}?dumplingCode=${pt.dumplingCode}`}
                  >
                    参与拼团
                  </Link>
                  {/*</button>*/}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="czpt-activity-detail">
          <h4 className="czpt-activity-detail-title">充值拼团规则</h4>
          <div className="czpt-activity-detail-context">
            <div
              className={"htmlContent"}
              dangerouslySetInnerHTML={{__html: detail}}
            ></div>
          </div>
        </div>
      </section>
      {showAlert && (
        <CustomAlert
          message={message}
          onClose={handleCloseAlert}
        />
      )}
    </main>
  );
}
